
import * as ExcelJs from 'exceljs'
import { saveAs } from 'file-saver';

export const exportXLSX = async (data, fileName = Date.now().toString(), removeColumns= false, mergeColumn= null) => {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const headers = Object.keys(data[0]);
    const rowHeight = 100;
  
    worksheet.addRow(headers);
    worksheet.getRow(1).font = { bold: true };
  
    data.forEach((item, index) => {
      const values = Object.values(item);
      worksheet.addRow(values);
  
      if (item.hasOwnProperty("Store Icon") || item.hasOwnProperty("Product Image")) {
        worksheet.getRow(index + 2).height = rowHeight;
      }
    });
  
  
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
      });
    });
  
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 0;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 12 ? 12 : maxLength;
    });
  
    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      const item = data[rowIndex];
      const imageUrl = item["Store Icon"] || item["Product Image"];
  
      if (imageUrl) {
        const formula = `IMAGE("${imageUrl.replace(/"/g, '""')}")`;
        const columnIndex = headers.indexOf("Store Icon") + 1 || headers.indexOf("Product Image") + 1;
        worksheet.getCell(rowIndex + 2, columnIndex).value = {
          formula: formula
        };
      }
    }
  
    if (removeColumns) worksheet.spliceRows(1, 1);
  
    if (mergeColumn) worksheet.mergeCells(mergeColumn);
  
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, fileName + fileExtension);
  };