import { useEffect, useState } from "react";
import { Button, Card, Input, List, message, Steps, Table, Tooltip, Typography, Upload } from "antd"
import { useGetData, usePostData } from "../../hooks/serviceGetways"
import { useParams, useHistory } from 'react-router-dom';
import { InfoCircleFilled, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import Viewer from 'react-viewer';
import DownloadReport from "../../components/DownloadReport";
import _ from "lodash";
import moment from "moment";
import { exportXLSX } from "../../hooks/excel";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const { Column } = Table;

let KML = () => {
    let [poles, setPoles] = useState([])
    let { id } = useParams()
    let history = useHistory()
    let { search } = useLocation()
    const searchParams = new URLSearchParams(search);
    const job = searchParams.get("job");
    let [fileList, setFileList] = useState([]);
    let [loading, setLoading] = useState(false)
    let [step, setStep] = useState(0)
    let getData = useGetData()
    let postData = usePostData()
    const [fileBarcodes, setFileBarcodes] = useState([]);
    const [notFoundPoles, setNotFoundPoles] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const handleFileRead = (file) => {
        setFileList(prev => [...prev, file])

        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result; // Dosyanın içeriği
            try {
                // Regex ile BARKOD_NO ve sayıları yakala
                const pattern = /<tr><td>BARKOD_NO<\/td><td>(\d+)<\/td><\/tr>/g;
                const matches = [];
                let match;
                while ((match = pattern.exec(content)) !== null) {
                    matches.push(match[1]); // Yakalanan sayıyı ekle
                }
                setFileBarcodes(prevState => [...prevState, { barcodes: matches, uid: file.uid, file: file.name }]); // State'e yaz

                message.success(`${file.name} dosyası başarılı bir şekilde yüklendi.`);
            } catch (err) {
                message.error("Dosya içeriği işlenirken bir hata oluştu.");
                console.error(err);

            }

        };

        reader.readAsText(file); // Dosyayı metin olarak oku
        return false; // Dosyanın otomatik yüklenmesini engelle

    };

    const findPole = () => {
        setLoading(true)
        postData('poles/find/barcode', { barcodes: fileBarcodes.flatMap(x => x.barcodes) })
            .then(({ result: { rows } }) => {
                setPoles(rows);
                setNotFoundPoles(fileBarcodes.flatMap(x => x.barcodes).filter(x => !rows.some(r => r.barcode == x || r.convertBarcode == x)))
                setStep(1);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const saveBarcodes = () => {
        setLoading(true)
        postData('poles/save/barcode', { barcodes: fileBarcodes.flatMap(x => x.barcodes), jobId: id })
            .then(({ result: { rows } }) => {
                setPoles(rows);
                message.success("Barkod listesi işlendi.");
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const changeJob = () => {
        setLoading(true)
        postData('poles/change/job', { barcodes: poles.filter(x => selectedRowKeys.includes(x._id)).map(x => x.barcode), newJobId: id })
            .then(({ result: { rows } }) => {
                setStep(2);
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const download = () => {
        const response = notFoundPoles.map(x => ({ "Barkod": x }))
        exportXLSX(response, `${_.snakeCase(job)}_Olmayan_Direk_Listesi.xlsx`)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        console.log(newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    return <Card title={"Barkod Ayıklama"}
        extra={<>
            {step === 1 && <div className="d-flex align-items-center justify-content-center gap-2">
                <Button type="default" disabled={loading} onClick={() => setStep(0)}>Dosya Yükle</Button>
                <Button type="primary" disabled={loading} onClick={() => changeJob()}>Direkleri bu işe aktar</Button>
                <Button type="default" disabled={loading} onClick={() => setStep(2)}>Direkleri bu işe aktarmadan devam et</Button>
            </div>}
            {step === 2 && <div className="d-flex align-items-center justify-content-center gap-2">
                <Button type="default" disabled={loading} onClick={() => setStep(1)}>Geri</Button>
                <Button type="default" disabled={loading} onClick={() => download()}>Dosyayı İndir</Button>
            </div>}

            {step === 0 && <div className="d-flex align-items-center justify-content-center gap-2"><Button type="primary" disabled={!fileBarcodes?.flatMap(x => x.barcodes)?.length} onClick={() => findPole()}>Direkleri Bul</Button>
                <Button type="primary" disabled={!fileBarcodes?.flatMap(x => x.barcodes)?.length} onClick={() => saveBarcodes()}>Barkodları bu iş için sisteme işle</Button>
            </div>}
        </>}>
        <Steps current={step}>
            <Steps.Step key={0} title="KML Dosya Yükle" />
            <Steps.Step key={1} title="Direk Listesi" />
            <Steps.Step key={2} title="Verisi Olmayan Direkler" />
        </Steps>
        {step === 0 && <div className="pt-4">
            <Upload
                multiple
                listType="picture-card"
                name="file"
                fileList={fileList}
                beforeUpload={handleFileRead}
                onRemove={(file) => {
                    setFileList(fileList?.filter(x => x.uid !== file.uid));
                    setFileBarcodes(fileBarcodes.filter(x => x.uid !== file.uid));
                }}
            >
                <Button icon={<UploadOutlined />}>Yükle</Button>
            </Upload>
            <div style={{ margin: '20px 0px' }}>
                <h4>Yüklenen Dosyaların İçeriği:</h4>
                <div className="d-flex align-items-start justify-content-around w-100 gap-4">{fileBarcodes.map((x, i) =>
                    <div className="d-flex flex-column">
                        <Typography.Text className="font-weight-500 border-bottom">{x.file}</Typography.Text>
                        <List style={{ height: '50vh', overflow: 'auto', scrollbarWidth: 'none' }} dataSource={x.barcodes}
                            renderItem={(item, i) => (<List.Item> {i + 1} : {item} </List.Item>)}>
                        </List>
                    </div>)}
                </div>
            </div>
        </div>}
        {step === 1 && <Table loading={loading} dataSource={poles} rowKey="_id" scroll={{ x: 850 }} pagination={false} rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange
        }}>
            <Column dataIndex="_id" key="_id" title="ID" align="center" render={(_id) => <Tooltip title={_id}><InfoCircleFilled className="mb-1" /></Tooltip>}></Column>
            <Column dataIndex="barcode" key="barcode" align="center" title="Barcode" ></Column>
            <Column dataIndex="poleType" key="poleType" align="center" title="Direk Tipi"></Column>
            <Column dataIndex="poleGenre" key="poleGenre" align="center" title="Direk TÜrü"></Column>
            <Column dataIndex="jobName" key="jobName" align="center" title="İş Adı" sorter={(a,b)=>a.jobName.localeCompare(b.jobName)} filters={_.chain(poles).uniqBy(x=>x.jobName).map(x=>({text:x,value:x})).value()}></Column>
            <Column dataIndex="sebekeUnsur" key="sebekeUnsur" align="center" title="Şebeke Unsur" sorter={(a,b)=>a.jobName.localeCompare(b.sebekeUnsur)} filters={_.chain(poles).uniqBy(x=>x.sebekeUnsur).map(x=>({text:x,value:x})).value()} ></Column>
            <Column dataIndex="country" key="country" align="center" title="İl"></Column>
            <Column dataIndex="district" key="district" align="center" title="İlçe"></Column>
            <Column dataIndex="outskirts" key="outskirts" align="center" title="Mahalle"></Column>
            <Column dataIndex="convertBarcode" key="convertBarcode" align="center" title="Çevrilmiş Barkod"></Column>
            <Column dataIndex="createdAt" key="createdAt" align="center" title="Eklenme Tarihi" render={(createdAt) => moment(createdAt).add(3, 'hours').format('DD.MM.YYYY HH:mm')}></Column>
        </Table>}
        {step === 2 &&
            <List style={{ height: '50vh', overflow: 'auto', scrollbarWidth: 'none' }} dataSource={notFoundPoles}
                renderItem={(item, i) => (<List.Item> {i + 1} : {item} </List.Item>)}>
            </List>
        }
    </Card>

}

export default KML